import React from "react";
import styled from "styled-components";

const ProjectTagStyles = styled.div`
  border-radius: 0.2em;
  padding: 0.3em 0.8em;
  color: var(--color-gray-600);
  font-size: var(--font-small-size);
  background: var(--color-gray-150);

  margin-right: 0.6em;
  &:last-child {
    margin-right: 0;
  }
`;

type TagProps = {
  tag: string;
};

const ProjectTag = ({ tag }: TagProps) => {
  return <ProjectTagStyles>{tag}</ProjectTagStyles>;
};

export default ProjectTag;
