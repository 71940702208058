import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import ProjectCardSmall from "../components/ProjectCardSmall";

const ProjectsPageStyles = styled.main`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;
  margin-top: 6rem;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .projects {
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
    gap: 0 2rem;
    margin: 0;
    margin-bottom: 6rem;

    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      grid-template-columns: repeat(6, 1fr);
      gap: 0 1rem;
      margin: 0;
      margin-bottom: 6rem;
    }

    @media ${(props) => props.theme.breakpoints.s} {
      gap: 0 1rem;
      margin: 0;
      margin-bottom: 6rem;
    }
  }
  .text-top {
    grid-column: 1 / span 12;
    margin-bottom: 1.5em;
    margin-bottom: 4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      margin-top: 3rem;
    }
    div {
      width: 30rem;
    }
  }
`;

type QueryTypes = {
  allSanityProject: {
    nodes: Array<ProjectTypes>;
  };
};

type ProjectTypes = {
  smallCardImage: {
    asset: {
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
  yOffset: number;
  _id: string;
  title: string;
  tags: Tags[];
  excerpt: string;
  slug: {
    current: string;
  };
};

export type Tags = {
  tag: string;
  _key: string;
};

const ProjectsPage = () => {
  const ProjectsStaticQuery = graphql`
    query ProjectsPageQuery {
      allSanityProject(sort: { fields: projectOrder }) {
        nodes {
          smallCardImage {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    width: 500
                    quality: 88
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          title
          tags {
            tag
            _key
          }
          excerpt
          slug {
            current
          }
          yOffset
          _id
        }
      }
    }
  `;

  const data = useStaticQuery<QueryTypes>(ProjectsStaticQuery);

  return (
    <Layout>
      <Seo title="Projects" />

      <ProjectsPageStyles>
        <div className="text-top">
          <div>
            <h1>My Projects</h1>
            <p>
              Here is a collection of some of my favorite projects I've designed
              and developed recently. It's a pleasure to share them with you.
            </p>
          </div>
        </div>

        <div className="projects">
          {data.allSanityProject.nodes.map((project: ProjectTypes) => (
            <ProjectCardSmall
              key={project._id}
              slug={project.slug.current}
              excerpt={project.excerpt}
              title={project.title}
              tags={project.tags}
              image={
                project.smallCardImage.asset.localFile.childImageSharp
                  .gatsbyImageData
              }
            />
          ))}
        </div>
      </ProjectsPageStyles>
    </Layout>
  );
};

export default ProjectsPage;
