import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Link } from "gatsby";

const AnimatedLinkStyles = styled(motion.div)`
  a {
    display: inline-block;
    text-decoration: none;
    color: var(--color-black);
    font-size: var(--font-title4-size);
    line-height: var(--font-title4-line-height);
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.4em;
      fill: none;
      stroke: var(--color-black);
      stroke-width: 1px;
      height: 1em;
    }

    .btn-text {
      display: inline-block;
      font-family: "Inter", Arial, sans-serif;
      font-weight: 600;
    }
  }
`;

type AnimatedLinkProps = {
  link: string;
  text: string;
};

const AnimatedLink = ({ link, text }: AnimatedLinkProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <AnimatedLinkStyles
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      <motion.div
        animate={{ x: hovered ? 16 : 0 }}
        transition={{
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
      >
        <Link to={link}>
          <span className="btn-text">{text}</span>

          <svg viewBox="0 0 35 18">
            <motion.g
              animate={{
                x: hovered ? [0, 40] : 40,
              }}
              transition={{ duration: 0.3 }}
            >
              <polyline points="27.5,3.4 33.1,9 27.5,14.6 " />
              <line x1="32.8" y1="9" x2="9.8" y2="9" />
            </motion.g>
            <motion.g
              animate={{
                x: hovered ? [-40, 0] : 0,
              }}
              transition={{ duration: 0.3 }}
            >
              <polyline points="27.5,3.4 33.1,9 27.5,14.6 " />
              <motion.line
                animate={{ scaleX: hovered ? [1, 1.3, 1] : 1 }}
                style={{ originX: 1 }}
                transition={{ duration: 0.5 }}
                x1="32.8"
                y1="9"
                x2="9.8"
                y2="9"
              />
            </motion.g>
          </svg>
        </Link>
      </motion.div>
    </AnimatedLinkStyles>
  );
};

export default AnimatedLink;
