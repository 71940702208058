import React from "react";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import ProjectTag from "./ProjectTag";
import AnimatedLink from "./AnimatedLink";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import type { Tags } from "../pages/projects";

const ProjectCardSmallStyles = styled.div`
  grid-column-end: span 4;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column-end: span 6;
    margin-bottom: 5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.center {
    grid-column: 5 / span 4;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
    }
  }

  .image-wrapper {
    height: 18rem;
    @media ${(props) => props.theme.breakpoints.m} {
      height: 14rem;
    }
    a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .gatsby-image-wrapper,
      .project-card-image {
        max-height: 100%;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
    h2 {
      margin-bottom: 0.24em;
    }
    .tags {
      display: flex;
      margin-bottom: 2em;
    }
    .excerpt {
      border-radius: 0.5em;
      text-align: center;
      margin-bottom: 1em;
    }
  }
`;

type ProjectData = {
  excerpt: string;
  title: string;
  slug: string;
  tags: Tags[];
  image: IGatsbyImageData;
  center?: boolean;
};

const ProjectCardSmall = ({
  title,
  tags,
  slug,
  image,
  excerpt,
  center,
}: ProjectData) => {
  return (
    <ProjectCardSmallStyles className={`${center ? "center" : ""}`}>
      <div className="image-wrapper">
        <Link to={`/projects/${slug}`} className="image-link">
          <GatsbyImage
            image={image}
            alt={title}
            imgClassName="project-card-image"
          />
        </Link>
      </div>

      <motion.div className="text">
        <h2>{title}</h2>
        <div className="tags">
          {tags.map((tag) => (
            <ProjectTag tag={tag.tag} key={tag._key} />
          ))}
        </div>
        <p className="excerpt">{excerpt}</p>
        <AnimatedLink link={`/projects/${slug}`} text="See Project" />
      </motion.div>
    </ProjectCardSmallStyles>
  );
};

export default ProjectCardSmall;
